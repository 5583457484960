var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isDropdownVisible ? _c('div', {
    staticClass: "z-50 bg-white absolute right-0 top-16 rounded-lg shadow w-4/6",
    attrs: {
      "id": "dropdown"
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center p-2 px-3 w-full"
  }, [_vm._m(0), _c('div', [_c('span', {
    staticClass: "cursor-pointer",
    on: {
      "click": _vm.handleClose
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-xmark text-gray-500"
  })])])]), _c('div', {
    staticClass: "w-full mt-2 mb-3"
  }, [_c('label', {
    staticClass: "mx-4 mb-2"
  }, [_vm._v("Vendor")]), _c('MultiSelectFilter', {
    attrs: {
      "options": _vm.vendorSearchOptions,
      "loading": _vm.isVendorSearch
    },
    on: {
      "search-change": _vm.vendorFind
    },
    model: {
      value: _vm.vendor,
      callback: function ($$v) {
        _vm.vendor = $$v;
      },
      expression: "vendor"
    }
  })], 1), _c('div', {
    staticClass: "w-full grid grid-cols-2 gap-3 justify-between px-4"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('label', {
    staticClass: "mb-2"
  }, [_vm._v("Item status")]), _c('StatusFilterComponent', {
    attrs: {
      "options": _vm.statusOptions
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1), _c('div', {
    staticClass: "w-full"
  }, [_c('label', {
    staticClass: "mb-2"
  }, [_vm._v("Content Type")]), _c('ContentTypeSelector', {
    attrs: {
      "options": _vm.contentTypeOptions
    },
    model: {
      value: _vm.contentType,
      callback: function ($$v) {
        _vm.contentType = $$v;
      },
      expression: "contentType"
    }
  })], 1)]), _c('div', {
    staticClass: "w-full flex justify-start px-4 mt-3 mb-3"
  }, [_c('button', {
    staticClass: "bg-blue-500 text-white px-4 py-2 rounded-lg mr-2",
    on: {
      "click": _vm.applyFilter
    }
  }, [_vm._v(" Filter ")]), _c('button', {
    staticClass: "bg-white text-gray-500 border px-4 py-2 rounded-lg",
    on: {
      "click": _vm.resetFilter
    }
  }, [_vm._v(" Reset ")])])]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "text-gray-500 font-light text-base mt-2"
  }, [_vm._v("Filter")])]);
}]

export { render, staticRenderFns }