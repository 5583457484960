<template>
  <div>
    <div 
      id="dropdown" 
      v-if="isDropdownVisible" 
      class="z-50 bg-white absolute right-0 top-16 rounded-lg shadow w-4/6 ">
      <div class="flex justify-between items-center p-2 px-3 w-full">
        <div>
          <h5 class="text-gray-500 font-light text-base mt-2">Filter</h5>
        </div>
        <div>
          <span class="cursor-pointer" @click="handleClose"><i class="fa-solid fa-xmark text-gray-500"></i></span>
        </div>
      </div>
      <div class="w-full mt-2 mb-3">
        <label class="mx-4 mb-2">Vendor</label>
        <MultiSelectFilter 
          :options="vendorSearchOptions"
          :loading="isVendorSearch"
          v-model="vendor"
          @search-change="vendorFind"
        />
      </div>
      <div class="w-full grid grid-cols-2 gap-3 justify-between px-4 ">
        <div class="w-full">
          <label class=" mb-2">Item status</label>
          <StatusFilterComponent 
            v-model="status"
            :options="statusOptions"
          />
        </div>
        <div class="w-full">
          <label class=" mb-2">Content Type</label>
          <ContentTypeSelector 
            v-model="contentType" 
            :options="contentTypeOptions" 
          />
        </div>
      </div>
     
      <div class="w-full flex justify-start px-4 mt-3 mb-3">
        <button 
          class="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2" 
          @click="applyFilter">
          Filter
        </button>
        <button 
          class="bg-white text-gray-500 border px-4 py-2 rounded-lg" 
          @click="resetFilter">
          Reset
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ContentTypeSelector from '../../components/form/ContentTypeSelector.vue';
import StatusFilterComponent from '../../components/form/StatusFilterComponent.vue';
import MultiSelectFilter from '../../components/form/MultiSelectFilter.vue';
import { mapActions } from 'vuex';
import constant from '../../store/constant';

const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map((item) => ({
  text: item[0],
  value: item[1]
}));

const ITEM_CONTENT_TYPE = Object.entries(constant.ITEM_CONTENT_TYPE).map((item) => ({
  text: item[0],
  value: item[1]
}));

export default {
  components: {
    ContentTypeSelector,
    MultiSelectFilter,
    StatusFilterComponent
  },
  props: {
    isDropdownVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      contentType: null,
      status: null,
      vendor: [],
      isVendorSearch: false,
      vendorSearchOptions: [],
      statusOptions: [...ITEM_STATUS],
      contentTypeOptions: [...ITEM_CONTENT_TYPE],
    };
  },
  watch: {
    isDropdownVisible(newVal) {
      if (newVal) {
        this.addOutsideClickListener();
      } else {
        this.removeOutsideClickListener();
      }
    }
  },
  
  methods: {
    ...mapActions('vendors', ['searchVendors']),
    
    applyFilter() {
      this.$emit('input', { contentType: this.contentType, status: this.status, vendor: this.vendor });
      this.$emit('reloadPage');
      
      this.handleClose(); 
    },
    
    resetFilter() {
      this.contentType = null;
      this.status = null;
      this.vendor = [];
      this.$emit('input', { contentType: this.contentType, status: this.status, vendor: this.vendor });
      this.$emit('reloadPage');
    },
    
    vendorFind(query) {
      if (!query) return;

      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchVendors(payload)
          .then((response) => {
            this.vendorSearchOptions = response.data.data.rows;
            this.isVendorSearch = false;
          })
          .catch(() => {
            this.isVendorSearch = false;
          });
      }, 600);
    },
    
    addOutsideClickListener() {
      setTimeout(() => { 
        document.addEventListener('click', this.handleOutsideClick);
      }, 0);
    },
    
    removeOutsideClickListener() {
      document.removeEventListener('click', this.handleOutsideClick);
    },
    
    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.$emit('update:isDropdownVisible', false);
      }
    },
    
    handleClose() {
      this.$emit('update:isDropdownVisible', false);
    }
  },
  
  beforeDestroy() {
    this.removeOutsideClickListener();
  }
}
</script>
