<template>
  <div class="px-4 flex w-full">
    <multiselect
      label="name"
      track-by="name"
      placeholder="e.g.: Gramedia Pustaka Utama"
      :close-on-select="false"
      :multiple="true"
      :options="options"
      :loading="loading"
      v-model="selected"
      @search-change="handleSearchChange"
      @input="handleInput"
    >
    <span slot="noResult">
      No result found, change your keyword or filter
    </span>
      <template #clear>
        <div class="multiselect__clear" v-if="selected.length" @mousedown.prevent.stop="handleClear"></div>
      </template>
    </multiselect>
  </div>
  
  </template>
  
  <script>
  export default {
    props: {
      options: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        default: false
      },
      value: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        selected: this.value
      };
    },
    methods: {
      handleSearchChange(query) {
        this.$emit('search-change', query);
      },
      handleInput(selected) {
        this.$emit('input', selected);
      },
      handleClear() {
        this.selected = [];
        this.$emit('input', []);
      }
    },
    watch: {
      value(newVal) {
        this.selected = newVal;
      }
    }
  };
  </script>
  
  <style>
 .multiselect {
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
}

.multiselect__tag {
  background: #93c5fd;
  color: #2563eb;

}

.multiselect__tags {
  border: 0px;
}
.border-red-custom {
    border: 1px solid #ef4444  !important;
}

.multiselect input[type='text']:focus {
  outline-style: none;
}

.multiselect [type='text']:focus {
  outline-offset: 0px;
  offset: var(--tw-ring-offset-color);
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: var(--tw-ring-offset-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: unset;
}

.multiselect__content-wrapper {
  overflow-y: scroll;
  top: 70px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-x: hidden;
}

.multiselect__content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #fff;
  background-color: #fff;
}

.multiselect__content-wrapper::-webkit-scrollbar {
  width: 2px;
  background-color: #fff;
}

.multiselect__content-wrapper::-webkit-scrollbar-thumb {
  background-color: #2563eb;
  border: 3px solid #333;
}

.multiselect__content-wrapper .multiselect__option--highlight {
  background-color: #2563eb;
  width: 100%;
}

.multiselect__option--highlight {}

.multiselect__content {
  max-width: max-content;
  font-size: 14px;
}

.multiselect__option--highlight {
  position: relative;
}

.multiselect__select:before {
  display: none;
}
  </style>
  